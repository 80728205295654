import "./referal_header.css";
import { brand_logo_two } from "../../assets/images";
import { Facebook, Instagram, YouTube } from "../../assets/images/icons";
import { Container, Navbar, NavDropdown, Nav } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import React, { useState } from "react";
export default function ReferalHeader(props) {
  const history = useHistory();
  const gotoOtherPage = (link) => {
    history.push(link);
  };
  const [show, setShow] = useState(false);
  const showDropdown = (e) => {
    setShow(!show);
  };
  const hideDropdown = (e) => {
    setShow(false);
  };
  return (
    <div className="heder_cont">
      <Navbar expand="lg" className="header" data-bs-theme="dark">
        <Container>
          <div className="py-0 navbar-brand">
            <img src={brand_logo_two} className="img-fluid" width={120} />
          </div>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="me-auto my-2 my-lg-0"
              style={{ maxHeight: "200px" }}
              navbarScroll
            >
              <Nav.Link
                href="javascript:void(0)"
                onClick={() => {
                  gotoOtherPage("/refer/" + localStorage.getItem("paramCode"));
                }}
              >
                HOME
              </Nav.Link>
              <NavDropdown
                title="INDUSTRIES"
                show={show}
                onMouseEnter={showDropdown}
                onMouseLeave={hideDropdown}
              >
                <NavDropdown.Item
                  href="javascript:void(0)"
                  onClick={() => {
                    gotoOtherPage("/real-estate");
                  }}
                >
                  Real Estate
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="javascript:void(0)"
                  onClick={() => {
                    gotoOtherPage("/insurance");
                  }}
                >
                  Insurance
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="javascript:void(0)"
                  onClick={() => {
                    gotoOtherPage("/digital-marketing");
                  }}
                >
                  Digital Marketing
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="javascript:void(0)"
                  onClick={() => {
                    gotoOtherPage("/small-business");
                  }}
                >
                  Small Business
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="javascript:void(0)"
                  onClick={() => {
                    gotoOtherPage("/sales-professionals");
                  }}
                >
                  Sales Professionals
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link
                href="javascript:void(0)"
                onClick={() => {
                  gotoOtherPage("/small-business");
                }}
              >
                HOW IT WORKS
              </Nav.Link>
              <Nav.Link
                href="javascript:void(0)"
                onClick={props.gotoOrderCheckoutForm}
              >
                MEMBERSHIPS
              </Nav.Link>
              <Nav.Link
                href="javascript:void(0)"
                onClick={() => {
                  gotoOtherPage("/affiliate");
                }}
              >
                BECOME AN AFFILIATE
              </Nav.Link>
              <Nav.Link
                href="javascript:void(0)"
                onClick={() => {
                  gotoOtherPage("/faq");
                }}
              >
                FAQ
              </Nav.Link>
              <Nav.Link
                href="javascript:void(0)"
                onClick={() => {
                  gotoOtherPage("/refer/" + localStorage.getItem("paramCode"));
                }}
              >
                CONTACT
              </Nav.Link>
              <Nav.Link href="/login">LOGIN</Nav.Link>
            </Nav>
            <div className="header_sl d-flex">
              <a
                href="https://www.facebook.com/tripvaletincentives"
                target="_blank"
              >
                <Facebook />
              </a>
              <a href="https://www.instagram.com/tripvalet/" target="_blank">
                <Instagram />
              </a>
              <a
                href="https://www.youtube.com/@tripvaletincentives"
                target="_blank"
              >
                <YouTube />
              </a>
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}
