import React, { useEffect } from "react";
import { useState } from "react";
import validator from "validator";

function useFormValidation() {
  const [isValid, setIsValid] = useState(true);
  const [errorMessage, setErrorMessage] = useState(false);
  const [inputName, setInputname] = useState("");
  const [report, setReport] = useState({});
  function validation(
    value = "",
    rules = {
      required: false,
      email: true,
      number: true,
      min: 1,
      max: 1,
      creditcard: true,
    },
    inputName
  ) {
    setInputname(inputName);
    let valid;
    let errorM;
    if (rules.required) {
      valid = !validator.isEmpty(value);
      errorM = "This field is required";
    }
    if (rules.package_name) {
      valid = !validator.isEmpty(value);
      errorM = "This field is required";
    }
    if (rules.firstName) {
      valid = !validator.isEmpty(value);
      errorM = "This field is required";
    }
    if (rules.lastName) {
      valid = !validator.isEmpty(value);
      errorM = "This field is required";
    }
    if (rules.phone) {
      valid = !validator.isEmpty(value);
      errorM = "This field is required";
    }
    if (rules.address) {
      valid = !validator.isEmpty(value);
      errorM = "This field is required";
    }
    if (rules.city) {
      valid = !validator.isEmpty(value);
      errorM = "This field is required";
    }
    if (rules.country) {
      valid = !validator.isEmpty(value);
      errorM = "This field is required";
    }
    if (rules.state) {
      valid = !validator.isEmpty(value);
      errorM = "This field is required";
    }
    if (rules.zipCode) {
      valid = !validator.isEmpty(value);
      errorM = "This field is required";
    }

    if (rules.email) {
      valid = validator.isEmail(value);
      setIsValid();
      errorM = "Please type a correct email";
    }
    if (rules.password) {
      var regularExpression =
        /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
      valid = regularExpression.test(value) && value.length >= 8;
      setIsValid();
      errorM =
        "Minimum 1 uppercase,1 number,1 special character and 8 letters long";
    }

    if (rules.number) {
      valid = validator.isNumeric(value);
      errorM = "Please type a correct number";
    }
    if (rules.date) {
      valid = validator.isDate();
      errorM = "Please type a correct date";
    }
    if (rules.creditcard) {
      valid = validator.isCreditCard(value);
      errorM = "Please enter valid Credit card Details";
    }
    if (rules.month) {
      valid = value > 0 && value <= 12;
      errorM = "Please enter correct month";
    }
    if (rules.year) {
      valid = value > 2022;
      errorM = "Please enter correct Year";
    }
    if (rules.currentYear) {
      valid = value >= new Date().getFullYear();
      errorM = "Please enter correct Year";
    }
    if (rules.minlength) {
      valid = value.length >= rules.minlength;
      errorM = "Please enter more than " + rules.minlength + "characters";
    }
    if (rules.maxlength) {
      valid = value.length <= rules.maxlength;
      errorM = "Please enter not more than " + rules.maxlength + "characters";
    }
    if (rules.cvv) {
      valid = validator.isNumeric(value) && value.length == 3;
      errorM = "Please enter valid CVV";
    }
    if (rules.passwordStrong) {
      let regPass =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/gm;
      if (!regPass.test(value)) {
        valid = false;
        errorM = "Please enter strong password";
      } else {
        valid = true;
      }
    }

    setIsValid(valid);
    setErrorMessage(errorM);

    return Object.assign({
      isValid,
      errorMessage,
    });
  }
  useEffect(() => {
    if (inputName !== "")
      setReport({ ...report, [inputName]: { isValid, errorMessage } });
  }, [isValid, errorMessage]);

  return [report, validation];
}

export default useFormValidation;
