import React from "react";
import {
  COMING_SOON_all_inclusive,
  COMING_SOON_cruise
} from "../../assets/images";

const SingleCerti = ({ certiImg, certificateTitle, certiId, setCerti, certiIndentity }) => {
  return (
    <label className="radio-img">
      {(certiIndentity != "TVIALLINCLUSIVE4NT" && certiIndentity != "TVICRUISEVACAY") ? (<>
        <input
          type="radio"
          name="layout"
          value={certiId}
          onChange={(e) => {
            setCerti(e.target.value);
          }}
        />
        <div className="image">
          <img src={certiImg} />
          <span>{certificateTitle}</span>
        </div>
      </>) : (<>
        {(certiIndentity == "TVIALLINCLUSIVE4NT") ? (<>
          <div className="image">
            <img src={COMING_SOON_all_inclusive} />
            <span>{certificateTitle}</span>
          </div>
        </>) : (<>
          <div className="image">
            <img src={COMING_SOON_cruise} />
            <span>{certificateTitle}</span>
          </div>
        </>)}
      </>)}
    </label>
  );
};

export default SingleCerti;
