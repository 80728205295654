import React, { Component } from "react";
import LoadingOverlay from "react-loading-overlay";
import axios from "../../../axios/axios";
import { message } from "antd";
import { Col, Row, Form, Button } from "react-bootstrap";
import { logo_image } from "../../../assets/images";
import { HeaderTwoLogo } from "../../../assets/images";
import './download-print.css';
import { useHistory } from "react-router-dom";
import { backImage } from "../../../assets/images";
import { QRCodeSVG } from "qrcode.react";

export default class DownloadTravelCertificate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,

      certificate_details: {},
      userDetails: {},
      certificateChangeLogo: '',
      previewImage: '',
      logoVisible: true,
    };
  }

  componentDidMount = () => {
    let userDetails = localStorage.getItem("userDetails");

    userDetails = userDetails ? JSON.parse(userDetails) : {};
    this.setState({ userDetails });
    this.setState({ previewImage: HeaderTwoLogo });
    if (userDetails.certificateOwnLogo) {
      this.setState({ previewImage: userDetails.certificateOwnLogo });
    }

    this.getCertificateDetails();
  };

  gotoBack = () => {
    window.location.href = '/print';
  }

  getCertificateDetails = () => {
    this.setState({ isLoading: true });
    axios
      .get(`/get-travel-certificate/${this.props.match.params.id}`)
      .then((res) => {
        if (res.data.status) {
          this.setState({ certificate_details: res.data.certificate_details });
          setTimeout(() => {
            this.setState({ isLoading: false });
            setTimeout(() => {
              //this.printDocument();
            }, 1000);
          }, 5000);
        } else {
          this.setState({ isLoading: false });
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        console.error(err);
        this.setState({ isLoading: false });
        message.error("Something went wrong");
      });
  };

  printDocument = () => {
    let printContents = document.getElementById("print_content").innerHTML;

    document.body.innerHTML = printContents;

    window.print();

    window.close();
  };

  logoUpload = async (e) => {
    e.preventDefault();
    if (this.state.certificateChangeLogo != '') {
      this.setState({ isLoading: true });
      let data = new FormData();
      data.append("logo", this.state.certificateChangeLogo);
      data.append("userId", this.state.userDetails.id);
      data.append("source", this.state.userDetails.source);

      await axios
        .post(`/certificate-user-wise-logo`, data)
        .then((res) => {
          console.log(res);
          this.setState({ isLoading: false });
          if (res.data.status === true) {
            document.getElementById("logo-upload-form").reset();
            message.success(res.data.message);
            let uDetails = localStorage.setItem("userDetails", JSON.stringify(res.data.data));
            uDetails = JSON.parse(uDetails)
            this.setState({ userDetails: uDetails });
          } else {
            message.error(res.data.message);
          }
        })
        .catch((err) => {
          this.setState({ isLoading: false });
          console.log(err);
        });


    } else {
      message.error("Please upload your logo!");
    }
  }

  removeLogo = (e) => {
    document.getElementById("logo-upload-form").reset();
    this.setState({ logoVisible: false });
    message.success('Logo remove successfully!');
  }
  render() {
    let { userDetails, certificate_details } = this.state;

    return (
      <LoadingOverlay spinner active={this.state.isLoading} text="Loading...">
        <div className="row form-box">
          <div className="col-md-12">
            <Form id="logo-upload-form">
              <Form.Group controlId="formBasicEmail" className="col-md-6">
                <h5> Change logo</h5>
                <input
                  type="file"
                  name="myfile"
                  onChange={(e) => {
                    console.log(e.target.files[0]);
                    this.setState({ certificateChangeLogo: e.target.files[0] });
                    if (e.target.files[0]) {
                      let pImg = URL.createObjectURL(e.target.files[0]);
                      this.setState({ previewImage: pImg });
                      this.setState({ logoVisible: true })
                    }
                  }}
                />

              </Form.Group>
              <Form.Group controlId="formBasicEmail" className="col-md-6 mt-3">
                <Button variant="primary" type="button" onClick={this.logoUpload}>
                  Submit
                </Button >
                <Button variant="danger" className="ml-2" type="button" onClick={this.removeLogo}>
                  Remove Logo
                </Button >
              </Form.Group>
            </Form>
          </div>


          <div className="col-md-12 mt-5">
            <Button variant="primary" className="float-left" type="button" onClick={() => { this.gotoBack(); }}>
              <img src={backImage} style={{ width: '20px' }} alt="" srcset="" /> Back
            </Button >
            <Button variant="primary" className="float-right" type="button" onClick={() => { this.printDocument(); }}>
              Print
            </Button >
          </div>
        </div>


        {/* <div
          className="pdf_inner_inner_box1"
          id="print_content"
        // width="2480px"
        // height="3508px"
        >
          <div className="pdf_boxi1">
            <div className="create_pdfff1">
              <div className="pdf_banner">
                <img
                  src={certificate_details.banner}
                  alt="Travel Certificate"
                />
              </div>
              <div className="pdf_content">
                <Row style={{ display: 'flex', flexWrap: 'wrap', marginRight: '-15px', marginLeft: '-15px' }}>
                  <Col lg="3" md="4" sm="12" className="padding_top30px">
                    <div className="pdf_price_img">
                      <img
                        src={certificate_details.pricetag}
                        className="img-fluid"
                      />
                    </div>
                    <div className="scan_code code_scan">
                      <img
                        src={`https://chart.googleapis.com/chart?cht=qr&chl=${certificate_details.qr_code}${userDetails?.name}/${certificate_details.certificate_code}/${userDetails?._id}%2F&chs=180x180&choe=UTF-8&chld=L%7C2`}
                        className="img-fluid"
                      />
                    </div>
                    <div className="pdf_logo_img logo_pdf">
                      <img src={this.state.previewImage} className="img-fluid" />
                    </div>
                  </Col>
                  <Col lg="9" md="8" sm="12">
                    <div className="certificate_terms inner_termmmms">
                      <h2>Terms of the Certificate</h2>
                      <div
                        className="text-justify"
                        dangerouslySetInnerHTML={{
                          __html: certificate_details.certificate_description,
                        }}
                      />

                      <h3>GET YOUR CERTIFICATE TODAY!</h3>
                      <div className="go_online online_go">
                        <p>Go Online: https://redeem.tripvalet.com/</p>
                        <p>Enter Referral Code: {userDetails?.name}</p>
                        <p>
                          Certificate Code:
                          {certificate_details.certificate_code}
                        </p>
                      </div>
                    </div>
                  </Col>
                  <div className="foot_cont">
                    <p>
                      If you have general questions about your certificate
                      please email support@tripvalet.com Monday to Friday from
                      9am to 5pm Central Time.
                    </p>
                  </div>
                </Row>
              </div>
            </div>
          </div>
        </div> */}



        <div
          className="pdf_inner_inner_box1"
          id="print_content"
        >
          <div className="pdf_boxi1">
            <div className="create_pdfff1">

              <table className="pdf_content" align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style={{ backgroundColor: "#fff", color: "#000000", width: "100%", margin: '0' }}>
                <tr>
                  <td style={{ paddingBottom: "30px" }}>
                    <div className="pdf_banner">
                      <img
                        src={certificate_details.banner}
                        style={{ maxWidth: "100%", maxHeight: '340px', objectFit: 'cover' }}
                        alt="Travel Certificate"
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <table align="center" border="0" cellpadding="0" cellspacing="0" style={{ backgroundColor: "#fff", width: '100%', color: "#000000" }}>
                      <tr>
                        <td lg="3" md="4" sm="12" valign="top" className="padding_top30px" style={{ padding: "8px 15px", width: '200px' }}>
                          <div className="pdf_price_img" tyle={{ marginBottom: "20px" }}>
                            <img
                              src={certificate_details.pricetag}
                              className="img-fluid" style={{ maxWidth: "100%" }}
                            />
                          </div>
                          <div className="scan_code code_scan" style={{ marginBottom: "20px" }}>
                            <QRCodeSVG className="img-fluid" style={{ maxWidth: "100%" }} value={`${certificate_details.qr_code}${userDetails?.name}/${certificate_details.certificate_code}/${userDetails?._id}`} />

                            {/* <img
                              src={`https://chart.googleapis.com/chart?cht=qr&chl=${certificate_details.qr_code}${userDetails?.name}/${certificate_details.certificate_code}/${userDetails?._id}%2F&chs=180x180&choe=UTF-8&chld=L%7C2`}
                              className="img-fluid" style={{ maxWidth: "100%" }}
                            /> */}
                          </div>
                          {(this.state.logoVisible) ? (<>
                            <div className="pdf_logo_img logo_pdf">
                              <img
                                src={this.state.previewImage}
                                className="img-fluid" style={{ maxWidth: "200px", maxHeight: '200px', objectFit: 'contain' }}
                              />
                            </div>
                          </>) : (<></>)}
                        </td>
                        <td lg="9" md="8" sm="12" valign="top" style={{ padding: "8px 15px", paddingRight: '20px' }}>
                          <div className="certificate_terms inner_termmmms">
                            <h2>Terms of the Certificate</h2>
                            <div
                              className="text-justify"
                              dangerouslySetInnerHTML={{
                                __html: certificate_details.certificate_description,
                              }}
                            />

                            <h3>GET YOUR CERTIFICATE TODAY!</h3>
                            <div className="go_online online_go">
                              <p>Go Online: https://redeem.tripvalet.com/</p>
                              <p>Enter Referral Code: {userDetails?.name}</p>
                              <p>
                                Certificate Code:
                                {certificate_details.certificate_code}
                              </p>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td style={{ padding: "8px 15px" }}>
                    <div className="foot_cont" style={{ textAlign: "center" }}>
                      <p>
                        If you have general questions about your certificate
                        please email mailto:support@tripvalet.com Monday to Friday from
                        9am to 5pm Central Time.
                      </p>
                    </div>
                  </td>
                </tr>
              </table>

            </div>
          </div>
        </div>

        {/* <div
          className="pdf_inner_inner_box1"
          id="print_content"
        // width="2480px"
        // height="3508px"
        >
          <div className="pdf_boxi1">
            <div className="create_pdfff1">
              <div className="pdf_banner">
                <img
                  src={certificate_details.banner}
                  alt="Travel Certificate"
                />
              </div>
              <div className="pdf_content">
                <Row>
                  <Col lg="3" md="4" sm="12" className="padding_top30px">
                    <div className="pdf_price_img">
                      <img
                        src={certificate_details.pricetag}
                        className="img-fluid"
                      />
                    </div>
                    <div className="scan_code code_scan">
                      <img
                        src={`https://chart.googleapis.com/chart?cht=qr&chl=${certificate_details.qr_code}${userDetails?.name}/${certificate_details.certificate_code}/${userDetails?._id}%2F&chs=180x180&choe=UTF-8&chld=L%7C2`}
                        className="img-fluid"
                      />
                    </div>
                    <div className="pdf_logo_img logo_pdf">
                      <img src={this.state.previewImage} className="img-fluid" />
                    </div>
                  </Col>
                  <Col lg="9" md="8" sm="12">
                    <div className="certificate_terms inner_termmmms">
                      <h2>Terms of the Certificate</h2>
                      <div
                        className="text-justify"
                        dangerouslySetInnerHTML={{
                          __html: certificate_details.certificate_description,
                        }}
                      />

                      <h3>GET YOUR CERTIFICATE TODAY!</h3>
                      <div className="go_online online_go">
                        <p>Go Online: https://redeem.tripvalet.com/</p>
                        <p>Enter Referral Code: {userDetails?.name}</p>
                        <p>
                          Certificate Code:
                          {certificate_details.certificate_code}
                        </p>
                      </div>
                    </div>
                  </Col>
                  <div className="foot_cont">
                    <p>
                      If you have general questions about your certificate
                      please email support@tripvalet.com Monday to Friday from
                      9am to 5pm Central Time.
                    </p>
                  </div>
                </Row>
              </div>
            </div>
          </div>
        </div> */}
      </LoadingOverlay >
    );
  }
}
